// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_objects/Function/bind#Polyfill

// Yes, it does work with `new funcA.bind(thisArg, args)`
if (!Function.prototype.bind)
	(function () {
		var ArrayPrototypeSlice = Array.prototype.slice;
		Function.prototype.bind = function (otherThis) {
			if (typeof this !== "function") {
				// closest thing possible to the ECMAScript 5
				// internal IsCallable function
				throw new TypeError(
					"Function.prototype.bind - what is trying to be bound is not callable"
				);
			}

			var baseArgs = ArrayPrototypeSlice.call(arguments, 1),
				baseArgsLength = baseArgs.length,
				fToBind = this,
				fNOP = function () {},
				fBound = function () {
					baseArgs.length = baseArgsLength; // reset to default base arguments
					baseArgs.push.apply(baseArgs, arguments);
					return fToBind.apply(
						fNOP.prototype.isPrototypeOf(this) ? this : otherThis,
						baseArgs
					);
				};

			if (this.prototype) {
				// Function.prototype doesn't have a prototype property
				fNOP.prototype = this.prototype;
			}
			fBound.prototype = new fNOP();

			return fBound;
		};
	})();
